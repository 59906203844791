<template>
    <form-layout
        ref="dataForm"
        :valid.sync="valid"
        :mode="mode"
        :form-title="schema.titleSingular"
        :hide-title="hideTitle"
        :hide-delete="hideDelete"
        :hide-cancel="hideCancel"
        :is-edit="isEdit"
        :is-saving="isSaving"
        :is-readonly="isReadonly"
        @save="save"
        @cancel="cancel()"
        @deleteItem="deleteItem()">
        <template v-slot:content="">
            <template v-for="field in filteredFields">
                <template v-if="field.pascalSingular !== 'Result'">
                    <component
                        :is="field.component"
                        v-if="!isFiltered(field.camelSingular)"
                        :key="field.value"
                        v-model="item[field.camelSingular]"
                        :field="field"
                        :filter="filter"
                        :is-edit="isEdit"
                        :is-readonly="isReadonly"
                        :is-dense="isDense"
                        :is-disabled="isDisabled"
                        :is-saving="isSaving"
                        :schema="schema"
                        :item="item"
                        @save="save" />
                </template>
                <template v-else>
                    <div
                        :key="field.value" 
                        class="d-flex flex-nowrap align-center justify-center">
                        <v-row>
                            <v-col cols="2">
                                <v-select
                                    v-model="item.precision"
                                    :items="enumValues" />
                            </v-col>
                            <v-col cols="10">
                                <component
                                    :is="field.component"
                                    :key="field.value"
                                    v-model="item[field.camelSingular]"
                                    :field="field"
                                    :filter="filter"
                                    :is-edit="isEdit"
                                    :is-saving="isSaving"
                                    :schema="schema"
                                    :item="item" />
                            </v-col>
                        </v-row>
                    </div>
                </template>
            </template>
        </template>
    </form-layout>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import enumService from "@/services/enumService";
import enumComponents from "@/enums/enumComponents";

export default {
    mixins: [formMixin],
    data() {
        return {
            enumComponents,
        };
    },
    computed: {
        filteredFields() {
            let fields = this.schema.fields.filter(e => e.pascalSingular !== "Precision");
            if (this.isEmpty(this.filter)) {
                return fields;
            }

            return fields.filter(
                h => !Object.prototype.hasOwnProperty.call(this.filter, h.camelSingular)
            );
        },
        enumValues() {
            let result = enumService.enumAsSelectList(
                this.enumComponents.filter(e => e.name === "Precision")[0].component
            );
            result[0].text = "=";
            result[1].text = "<";
            result[2].text = ">";
            return result;
        },
    },
    watch: {
        item: {
            immediate: true,
            handler() {
                if (this.isEmpty(this.item.precision)) {
                    this.item.precision = "Equal";
                }
            }
        }
    }
};
</script>
